import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import { IGetAllSettingPayload } from "./companySetting.api.types";

const companySettingApi = {
  getAllSetting: (payload: IGetAllSettingPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/dictionary/settings/get_company_setting.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
};

export default companySettingApi;
