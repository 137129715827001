export const NAV_BAR = [
    {text: 'Home', path: '/', auth: false},
    {text: 'eCoupon', path: '/e-coupon', auth: true},
    {text: 'Promotion', path: '/promotion', auth: false},
    {text: 'Gift', path: '/gift', auth: false},
]

export type NAV_BAR_TYPE = {
    text: string,
    path: string,
    auth: boolean
}