import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type { IGetListEventPayload, IGetEventDetailPayload, IGetPromotionBannerPayload } from "./event.api.types";

const eventApi = {
  getListEvent: (payload: IGetListEventPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/promotion/events/list.json", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  getEventDetail: (payload: IGetEventDetailPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/promotion/events/detail.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  getPromotionBanner: (payload: IGetPromotionBannerPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/dictionary/settings/get_setting.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  }
};

export default eventApi;
