import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type { FetchNotificationsPayload } from "./notification.api.types";

const notificationApi = {
  fetchEvents: (payload: FetchNotificationsPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/member_pushes/list.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
};

export default notificationApi;
