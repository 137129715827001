import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type { ILoginPayload, ILogoutPayload, IRegisterPayload, IRequestOtpPayload, IResetPasswordPayload, IVerifyOtpPayload } from "./auth.api.types";

const authApi = {
  login: (payload: ILoginPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/members/login.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  logout: (payload: ILogoutPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/members/logout.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  register: (payload: IRegisterPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/members/register.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  requestOTP: (payload: IRequestOtpPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/members/resend_verify_code.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  verifyOTP: (payload: IVerifyOtpPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/members/verify_sms_code.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
  resetPassword: (payload: IResetPasswordPayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/members/reset_password.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
};

export default authApi;
