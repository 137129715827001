import commonAxios from "@/utils/axios/common.axios";

import type { AxiosResponseData } from "@/utils/axios";
import type { IGetProfilePayload } from "./user.api.types";

const userApi = {
  getProfile: (payload: IGetProfilePayload) => {
    return commonAxios.post<AxiosResponseData>("/api/member/members/profile.json", {
      ...payload.params,
      cancelToken: payload.cancelToken,
    });
  },
};

export default userApi;
